import httpClient from "@/services/httpClient"
import TokenService from "@/services/storage"
import {parseJWT} from "@/services/utils"
import {UserRoles} from '@/common/constants';


const getters = {
  userDetails: () => {
    return parseJWT(TokenService.getToken())?.data.user;
  },
  userHasRoles: () => roles => {
    const {user} = parseJWT(TokenService.getToken()).data;
    const intersection = roles.filter((v) => user.roles.includes(v));
    return intersection && intersection.length;
  },
  isAdmin: () => {
    return getters.userHasRoles()([UserRoles.admin, UserRoles.superadmin]);
  },
  isCustomer: () => {
    return getters.userHasRoles()([UserRoles.customer]);
  }
}

const state = () => ({
  user: null,
})

const actions = {
  async login({commit}, payload) {
    try {
      const response = await httpClient.post('jwt-auth/v1/token', payload);
      const userData = response.data;
      TokenService.saveToken(userData.token);
      userData.id = parseJWT(userData.token).data.user.id;
      commit('LOGGED_SUCCESS', parseJWT(userData.token).data.user);
      return true;
    } catch (error) {
      //console.error(error)
      return false;
    }
  },
  async logout({commit}) {

    TokenService.removeToken();
    //const response = await httpClient.post('/api/v1/dgr/logout');
    //console.log(response);
    commit('LOGOUT_SUCCESS');

  }
}

const mutations = {
  LOGGED_SUCCESS(state, data) {
    console.warn(state.user);
    state.user = data;
  },
  LOGOUT_SUCCESS(state) {
    state.user = null;
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
