import { createStore } from 'vuex'
import globalModule from '@/store/modules/global'
import AuthModule from '@/store/modules/auth'

export default createStore({
  state     : {},
  mutations : {},
  actions   : {},
  getters   : {},
  modules   : {
    AuthModule,
    globalModule
  }
})
