<template>
  <div>
    <slot/>
  </div>
</template>
<script>

export default ({
  name: 'AppLayout',
  props: {},
  setup() {
    return {}
  },
});

</script>
<style scoped>
.header {
  height: 5rem;
}
</style>