<template>
    <div class="p-5">
        <div>
            <!-- // Error : text-red-100 bg-red-700 border border-red-700 -->
            <!-- // Info : text-yellow-700 bg-yellow-100 border border-yellow-300 -->
            <!-- // Sucess : text-green-100 bg-green-700 border border-green-700 -->
            <div v-bind:class="errorType" class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-md">
                <!-- Error icon -->
                <div slot="avatar" v-if="type == 'info'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info w-5 h-5 mx-2">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="16" x2="12" y2="12"></line>
                        <line x1="12" y1="8" x2="12.01" y2="8"></line>
                    </svg>
                </div>
                <!-- Info icon -->
                <div slot="avatar" v-if="type == 'error'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-octagon w-5 h-5 mx-2">
                        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                    </svg>
                </div>
                <!-- Success icon -->
                <div slot="avatar" v-if="type == 'success'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle w-5 h-5 mx-2">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                    </svg>
                </div>
                <div class="text-md font-normal  max-w-full flex-initial">
                    {{ title }}
                    <div class="text-sm font-base" v-if="description">{{ description }}</div>
                </div>
                <!-- <div class="flex flex-auto flex-row-reverse">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-yellow-400 rounded-full w-5 h-5 ml-2">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ref , computed , toRefs} from 'vue'

export default defineComponent({
  name: 'Footer',
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    }
  },
  setup(props) {

    const { type } = toRefs(props)


    console.log(type.value)

    const errorType = computed(() => {
        console.log(type.value )
        if(type.value == "info"){
            return " text-yellow-700 bg-yellow-100 border border-yellow-300 "
        }else if(type.value == "success"){
            return " text-green-100 bg-green-700 border border-green-700 "
        }else{
            return " text-red-100 bg-red-700 border border-red-700 "
        }
      
    })

    return {
      errorType
    }

  },
})
</script>

<style scoped lang="scss">
</style>