import axios from 'axios';
import TokenService from './storage';

const httpClient = {

  init(baseURL: string = process.env.VUE_APP_API_URL, isSecure: boolean = true) {
    axios.defaults.baseURL = baseURL;
    this.interceptors();
  },
  backend(url: string) {
    return process.env.VUE_APP_BACKEND_URL + url;
  },
  website(url: string) {
    return process.env.VUE_APP_WEBSITE_URL + url;
  },
  setHeader(customHeader: object) {
    axios.defaults.headers.common = customHeader;
  },
  get(ressource: string, params?: {}) {
    return axios.get(ressource, {params});
  },
  post(ressource: string, data: {}) {
    return axios.post(ressource, data);
  },
  put(ressource: string, data: {}) {
    return axios.put(ressource, data);
  },
  delete(ressource: string, data: {}) {
    return axios.delete(ressource, data);
  },
  customRequest(data: object) {
    return axios(data);
  },
  interceptors() {
    axios.interceptors.request.use(function(config) {
      if (TokenService.getToken()) {
        config.headers.Authorization = `Bearer ${TokenService.getToken()}`;
      }
      return config;
    }, error => Promise.reject(error));

    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response.status === 500) {
        console.error('Something Went Wrong! Please try again');
      }
      if (error.response.status === 401) {
        console.error('Invalid or expired token, Please log in again');
        TokenService.removeToken();
        // Test porpuse
        window.location.href = process.env.VUE_APP_WEBSITE_URL + '/wp-login.php?action=logout';
        location.reload();
        // store.dispatch('AuthModule/logout')
      }
      return Promise.reject(error);
    });
  },
};
export default httpClient;
