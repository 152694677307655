
import { defineComponent } from 'vue';
import { ref , computed , toRefs} from 'vue'
import { useStore } from 'vuex';


export default defineComponent({
  name: 'Loader',
  props: {
    isActive: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const store     = useStore()
    const isLoading = computed(() => {
        return store.state.globalModule.isLoading
    })
    return {
      isLoading
    }

  },
});

