const state = () => ({
  isLoading: false,
  isAppReady: false,
  headerTitle: null
})

const actions = {
  setLoading({commit}, payload) {
    commit('SET_LOADING', payload);
  },
  setAppReady({commit}, payload) {
    commit('APP_READY', payload)
  },
  setHeaderTitle({commit}, payload) {
    commit('SET_TITLE', payload)
  }

}

const mutations = {
  SET_LOADING(state, data) {
    state.isLoading = data
  },
  APP_READY(state, data) {
    state.isAppReady = data
  },
  SET_TITLE(state, data) {
    state.headerTitle = data
  }
}

const getters = {}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}
