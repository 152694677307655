import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// @ts-ignore
import store from './store/';
import {createI18n} from 'vue-i18n';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Textarea from 'primevue/textarea';
import Multiselect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import AppLayout from './layouts/AppLayout.vue';
import RadioButton from 'primevue/radiobutton';
import Calendar from 'primevue/calendar';
import './assets/styles/tailwind.css';
import './assets/styles/spinner.css';
import httpClient from '@/services/httpClient';
import Snackbar from '@/components/common/Snackbar.vue';
import Loader from '@/components/common/Loader.vue';

const loadLocaleMessages = () => {
  const locales = require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = { };
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // @ts-ignore
      messages[locale] = locales(key);
    }
  });
  return messages;
};

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

// httpClient Init
httpClient.init();
const app = createApp(App as any);

app.use(router);
app.use(store);
app.use(PrimeVue);
app.use(i18n);
app.component('AppLayout', AppLayout);
app.component('Snackbar', Snackbar);
app.component('Loader', Loader);
app.component('Multiselect', Multiselect);
app.component('Dropdown', Dropdown);
app.component('OverlayPanel', OverlayPanel);
app.component('InputText', InputText);
app.component('Button', Button);
app.component('RadioButton', RadioButton);
app.component('Calendar', Calendar);
app.component('Textarea', Textarea);
app.directive('tooltip', Tooltip);
app.mount('#app');
