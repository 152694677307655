
import { defineComponent } from 'vue';
import { ref , computed , toRefs} from 'vue'

export default defineComponent({
  name: 'Footer',
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    }
  },
  setup(props) {

    const { type } = toRefs(props)


    console.log(type.value)

    const errorType = computed(() => {
        console.log(type.value )
        if(type.value == "info"){
            return " text-yellow-700 bg-yellow-100 border border-yellow-300 "
        }else if(type.value == "success"){
            return " text-green-100 bg-green-700 border border-green-700 "
        }else{
            return " text-red-100 bg-red-700 border border-red-700 "
        }
      
    })

    return {
      errorType
    }

  },
})
