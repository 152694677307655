const TOKEN_KEY = 'DGR-APP-TOKEN';

function parseCookies() : any{
    return document.cookie
    .split(';')
    .reduce((res, c) => {
      const [key, val] = c.trim().split('=').map(decodeURIComponent)
      try {
        return Object.assign(res, { [key]: JSON.parse(val) })
      } catch (e) {
        return Object.assign(res, { [key]: val })
      }
    }, {});
}
function delete_cookie( name : string, path? : string , domain? : string ) {
  console.log("domain",domain)
  console.log("path",path)
  if( get_cookie( name ) ) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
function get_cookie(name : string){
  return document.cookie.split(';').some(c => {
      return c.trim().startsWith(name + '=');
  });
}
const TokenService = {
    getToken(): string | null {
        const cookieObject = parseCookies();
        if(cookieObject && cookieObject[TOKEN_KEY]){
            let TMPCookie = cookieObject[TOKEN_KEY];
            localStorage.setItem(TOKEN_KEY, TMPCookie);
            //delete_cookie(TOKEN_KEY);
            return TMPCookie;
        }
        return localStorage.getItem(TOKEN_KEY);
    },

    saveToken(accessToken: string) {
        localStorage.setItem(TOKEN_KEY, accessToken);
    },

    removeToken(): void {
        document.cookie = TOKEN_KEY+'=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        document.cookie = "";
        //delete_cookie(TOKEN_KEY,"/",location.hostname);
        localStorage.removeItem(TOKEN_KEY);
    },

};

export default TokenService;
