import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import checkAuth from '@/middlewares/checkAuth';
import TokenService from '@/services/storage';
import {parseJWT} from '@/services/utils';
import { useRouter, useRoute } from 'vue-router'

const Role = {
  superadmin: 'superadmin',
  admin: 'administrator',
  customer: 'customer',
};

const routes: RouteRecordRaw[] = [
  // Real routes
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: (to, from, next) => {
      const currentToken = TokenService.getToken();
      const router = useRouter()
      if(currentToken){
        router.push({ name: 'Orders'})
      }else{
        window.location.href = process.env.VUE_APP_WEBSITE_URL + '/wp-login.php?action=logout';
        console.log("here")
        return
      }
      return
    },
    meta: {
      requiresAuth: false,
      title: 'Login page title',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/MyProfile.vue'),
    meta: {
      requiresAuth: [ Role.superadmin, Role.admin, Role.customer ],
      title: 'Profile page title',
    },
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: () => import('@/views/MyProfile.vue'),
    meta: {
      requiresAuth: [ Role.superadmin, Role.admin, Role.customer ],
      title: 'Profile page title',
    },
  },
  // End Real routes
  {
    path: '/customer/:id',
    name: 'CustomerProfile',
    component: () => import('@/views/CustomerProfile.vue'),
    meta: {
      title: 'Customer profile page title',
      requiresAuth: [ Role.superadmin, Role.admin ],
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('@/views/Customers.vue'),
    meta: {
      title: 'Customers page title',
      requiresAuth: [ Role.superadmin, Role.admin ],
    },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Orders.vue'),
    meta: {
      requiresAuth: [ Role.superadmin, Role.admin, Role.customer ],
      title: 'Orders page title',
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services.vue'),
    meta: {
      requiresAuth: [ Role.superadmin, Role.admin, Role.customer ],
      title: 'Orders page title',
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services.vue'),
    meta: {
      requiresAuth: [ Role.superadmin, Role.admin, Role.customer ],
      title: 'Services page title',
    },
  },
  {
    path: '/single-order/:id',
    name: 'SingleOrder',
    component: () => import('@/views/SpecificOrder.vue'),
    meta: {
      requiresAuth: [ Role.superadmin, Role.admin, Role.customer ],
      title: 'Single order page title',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      requiresAuth: false,
      title: '404',
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { requiresAuth } = to.meta as any;
  const currentToken = TokenService.getToken();

  if (requiresAuth) {
    if (!currentToken) {
      // not logged in so redirect to login page with the return url
      window.location.href = process.env.VUE_APP_WEBSITE_URL + '/my-account';
      return
    }

    const currentUserData = parseJWT(currentToken as string).data.user;

    if (requiresAuth.length) {
      const intersection = requiresAuth.filter((v: any) => currentUserData.roles.includes(v));

      if (intersection.length === 0) {
        console.warn('path restricted by role');
        return next({ path: '/' });
      }
    }
  }

  next();

  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (!TokenService.getToken()) {
  //     next({
  //       path: '/login',
  //     });
  //   } else {
  //     next();
  //   }
  // } else {
  //   if (TokenService.getToken()) {
  //     next({
  //       path: '/my-profile',
  //     });
  //   } else {
  //     next();
  //   }
  // }
});

export default router;


